import axios from 'config/axios';
import { IResultResponse } from '../models';
import {
  Races,
  License,
  AgeGroup,
  Language,
  Pronounce,
  Specialities,
  LGBTQIdentity,
  PaymentOption,
  GenderIdentity,
  SexualOrientations,
} from '../models/global';

export const getPronounces = async () => {
  const response = await axios.get<IResultResponse<Pronounce[]>>(`/user/pronouns`);
  return response.data;
};

export const getGenderIdentities = async () => {
  const response = await axios.get<IResultResponse<GenderIdentity[]>>(`/user/gender/identities`);
  return response.data;
};

export const getSexualOrientations = async () => {
  const response = await axios.get<IResultResponse<SexualOrientations[]>>(`/user/sexual/orientations`);
  return response.data;
};

export const getUserRaces = async () => {
  const response = await axios.get<IResultResponse<Races[]>>(`/user/races`);
  return response.data;
};

export const getAgeGroups = async () => {
  const response = await axios.get<IResultResponse<AgeGroup[]>>(`/user/age/groups`);
  return response.data;
};

export const getLGBTQIdentities = async () => {
  const response = await axios.get<IResultResponse<LGBTQIdentity[]>>(`/user/lgbtq/identities`);
  return response.data;
};

export const getLanguages = async () => {
  const response = await axios.get<IResultResponse<Language[]>>(`/user/languages`);
  return response.data;
};

export const getPaymentOptions = async () => {
  const response = await axios.get<IResultResponse<PaymentOption[]>>(`/user/payment/options`);
  return response.data;
};

export const getSpecialties = async () => {
  const response = await axios.get<IResultResponse<Specialities[]>>(`/user/specialties`);
  return response.data;
};

export const getLicenses = async () => {
  const response = await axios.get<IResultResponse<License[]>>(`/user/licenses`);
  return response.data;
};
