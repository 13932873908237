import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { Colors, FontFamily, FontSize, FontWeight } from './styles/variables';

export const StyledContainer = styled(ToastContainer)`
  --toastify-toast-width: 362px;

  .Toastify__toast-icon {
    margin-inline-end: 21px;
    width: 45px;
  }

  .Toastify__toast-theme--light {
  }

  .Toastify__toast--success {
    background: ${Colors.Primary};
    color: ${Colors.White};
  }

  .Toastify__toast-theme--colored.Toastify__toast--success {
    border-radius: 10px;
  }

  .Toastify__toast-body > div:last-child div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-family: ${FontFamily.Primary};

    h1 {
      margin: 0;
      font-size: ${FontSize.FontXL};
      font-weight: ${FontWeight.Bold};
      line-height: 110%;
    }
    span {
      font-size: ${FontSize.FontLG};
      font-weight: ${FontWeight.ExtraBold};
      line-height: 110%;
    }
  }
`;
