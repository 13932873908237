import styled from 'styled-components';
import Popover from '@mui/material/Popover';
import { Colors, FontFamily, FontSize, FontWeight } from 'styles/variables';

export const SDropdownActions = styled.div`
  cursor: pointer;
`;

export const SPopover = styled(Popover)`
  &.MuiPopover-root {
    .MuiPaper-root {
      min-width: 80px;
      margin-top: -4px;
    }

    .actions-wrapper {
      list-style-type: none;
      background: ${Colors.White};
      border-radius: 4px;
      padding: 12px 0;
      box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.20), 0 3px 14px 0 rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.14);

      li {
        text-transform: none;
        cursor: pointer;
        width: 100%;
        padding: 8px 16px;
        font-size: ${FontSize.FontMD};
        font-weight: ${FontWeight.Bold};
        font-family: ${FontFamily.Primary};

        &.delete-action {
          color: ${Colors.Red};
        }

        &.green-action {
          color: ${Colors.Green};
        }

        &.disabled {
          color: ${Colors.TextPrimary};
          cursor: not-allowed;
        }

        &:hover {
          background-color: ${Colors.Primary}22;
        }
    }
  }
`;
