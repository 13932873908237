import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { TButtonProps } from './Button.types';
import { SButton } from './Button.styles';

const Button = ({
  loading,
  loadingPosition = 'left',
  variant = 'contained',
  fontSize,
  fontWeight,
  fontFamily,
  children,
  ...props
}: TButtonProps) => {
  return (
    <SButton
      $loadingPosition={loadingPosition}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $fontFamily={fontFamily}
      variant={variant}
      {...props}
    >
      <span className='button-content'>
        {loading && (
          <span className='loading-wrapper'>
            <CircularProgress size={12} />
          </span>
        )}
        {children}
      </span>
    </SButton>
  );
};

export default Button;
export type { TButtonProps };
