import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from 'components/Shared/Header';
import { USER_PRIVATE_BASE_URL } from 'routes/Routes.types';
import { SLayout } from 'components/Shared/Layout/Layout.styles';
import { tabs } from './Layout.data';

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const slag = location.pathname.split('/')[2];

  const onTabChange = (e: React.SyntheticEvent, tab: string) => {
    const matchingTab = tabs.find((t) => t.value === tab);

    if (matchingTab?.external) {
      window.open(matchingTab.value, '_blank');
      return;
    }

    navigate(`${USER_PRIVATE_BASE_URL}/${tab}`);
  };

  return (
    <SLayout>
      <Header tabs={tabs} activeTab={tabs.some((i) => i.value === slag) ? slag : null} onTabChange={onTabChange} />
      <div className='page-content'>
        <Outlet />
      </div>
    </SLayout>
  );
};

export default Layout;
