import styled from 'styled-components';
import { Breakpoint } from 'styles/variables';

export const SLayout = styled.div`
  height: 100vh;
  display: flex;

  .left-section {
    flex: 1;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #1c74bc 0%, #65b7fa 100%);
  }

  .right-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: ${Breakpoint.Large}) {
    .left-section {
      svg {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: ${Breakpoint.Small}) {
    flex-direction: column;

    .left-section {
      background: none;
      flex: 0;
      padding: 52px 8px 0;
    }
  }
`;
