import styled from 'styled-components';
import Button from '@mui/material/Button';
import { Colors, FontFamily, FontSize, FontWeight } from 'styles/variables';
import { TStyledButtonProps } from './Button.types';

export const SButton = styled(Button)<TStyledButtonProps>`
  &.MuiButton-root {
    line-height: 17px;
    padding: 12px 24px;
    text-transform: none;
    border-radius: 10px;

    &.MuiButton-sizeSmall {
      padding: 8px 16px;

      .button-content {
        font-size: ${({ $fontSize }) => $fontSize || FontSize.FontSM};
      }
    }

    .button-content {
      display: flex;
      align-items: center;
      position: relative;
      white-space: nowrap;
      font-size: ${({ $fontSize }) => $fontSize || FontSize.FontMMD};
      font-family: ${({ $fontFamily }) => $fontFamily || FontFamily.Primary};
      font-weight: ${({ $fontWeight }) => $fontWeight || FontWeight.Bold};
      letter-spacing: 0.85px;
      line-height: 100%;
    }

    .loading-wrapper {
      display: flex;
      position: absolute;
      left: ${({ $loadingPosition }) => ($loadingPosition === 'left' ? '-18px' : '')};
      right: ${({ $loadingPosition }) => ($loadingPosition === 'right' ? '-18px' : '')};
      top: 50%;
      transform: translate(0, -50%);
      z-index: 1;
    }

    &.MuiButton-text {
      width: fit-content;
      background: transparent;
      padding: 0;
    }

    &.MuiButton-containedSecondary {
      color: ${Colors.White};
    }

    &.MuiButton-contained {
      min-width: unset;

      .MuiCircularProgress-root {
        color: ${Colors.White};
      }
    }
  }
`;
