import styled from 'styled-components';

export const SAdminLayout = styled.div`
  position: relative;
  min-height: 100vh;

  .background-logo {
    position: absolute;
    opacity: 0.05;
    right: 0;
    z-index: -1;
  }

  .admin-page-content {
    padding: 24px 48px;
    min-height: calc(100vh - 90px);
  }
`;
