import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { Colors } from 'styles/variables';

export const SDrawer = styled(Drawer)`
  &.MuiDrawer-root {
    top: 90px;
    right: unset;

    .MuiDrawer-paper {
      box-sizing: border-box;
      padding: 40px 30px;
      top: 90px;
      height: calc(100% - 90px);
      min-width: 380px;
    }

    .user-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .view-profile {
      display: flex;
      align-items: center;
      column-gap: 12px;
      cursor: pointer;
    }

    .logout-btn {
      padding: 6px 32px;
      border-radius: 20px;
    }

    .divider {
      border-radius: 12px;
      border: 2px solid ${Colors.Primary};
      margin: 32px 0;
    }

    .menu-items {
      .menu-item {
        display: flex;
        align-items: center;
        column-gap: 12px;
        padding: 12px 24px;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 4px;

        &.active {
          background: ${Colors.Primary};

          .menu-item-label {
            color: ${Colors.White};
          }

          .menu-item-icon {
            svg {
              fill: white;
            }
          }
        }

        .menu-item-icon {
          width: 32px;

          svg {
            vertical-align: middle;
          }
        }
      }
    }
  }
`;
