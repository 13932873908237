import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { Colors } from 'styles/variables';

export const SDrawer = styled(Drawer)`
  &.MuiDrawer-root {
    .MuiDrawer-paper {
      box-sizing: border-box;
      padding: 18px 32px;
      border-radius: 16px 0 0 16px;
      min-width: 254px;
    }

    .divider {
      border-radius: 12px;
      border: 2px solid ${Colors.Primary};
      margin: 32px 0;
    }

    .menu-items {
      .menu-item {
        display: flex;
        align-items: center;
        column-gap: 12px;
        padding: 12px 0;
        border-radius: 8px;
        cursor: pointer;
        margin-bottom: 4px;
      }
    }

    .rainbow-laces {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
`;
