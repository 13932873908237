import styled from 'styled-components';
import { Breakpoint, Colors, FontFamily, FontSize, FontWeight } from 'styles/variables';

export const STerms = styled.div`
  position: relative;
  min-height: 100vh;

  .page-content {
    padding: 24px 48px;
    min-height: calc(100vh - 96px);

    .title {
      margin-bottom: 62px;
    }
    .sub-title {
      margin-bottom: 16px;
      margin-top: 26px;
    }

    .text-style {
      font-family: ${FontFamily.Secondary};
      color: ${Colors.Primary};
      font-size: ${FontSize.FontSM};
      line-height: 100%;
      margin-bottom: 10px;
    }

    .list-style {
      list-style-type: disc;
      margin-left: 1.5rem;

      li::marker {
        color: ${Colors.Primary};
      }
    }

    .list-style-none {
      list-style: none;
    }

    .bold-text {
      font-weight: ${FontWeight.Bold};
    }

    .big-separator {
      margin-bottom: 150px;
    }
  }

  @media screen and (max-width: ${Breakpoint.Small}) {
    .page-content {
      padding: 12px 14px;
    }
  }
`;
