import React from 'react';
import { STerms } from './Terms.styles';
import Typography from '../../components/Base/Typography';
import { Colors, FontFamily, FontSize } from '../../styles/variables';

const Terms = () => {
  return (
    <STerms>
      <div className='page-content'>
        {/* Terms of Use & Policies */}
        <div className='title'>
          <Typography
            variant='h1'
            color={Colors.Primary}
            fontFamily={FontFamily.Secondary}
            fontSize={FontSize.FontXXXL}
          >
            Terms of Use & Policies
          </Typography>
        </div>
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Terms of Use
          </Typography>
        </div>
        <div>
          <Typography className='text-style'>
            Welcome to our website. If you continue to browse and use this website, you are agreeing to comply with and
            be bound by the following terms and conditions of use, which, together with our privacy policy, govern
            You:Flourish P.B.C.’s relationship with you in relation to this website. The term You:Flourish P.B.C., or
            ‘us’ or ‘we’ refers to the owner of the website, whose registered office is 303 E 17th Ave Fourth floor,
            Denver, CO 80203. The term ‘you’ refers to the user or viewer of our website. The use of this website is
            subject to the following terms of use:
          </Typography>
        </div>
        <div>
          <ul className='list-style'>
            <li>
              <Typography className='text-style'>
                The content of the pages of this website is for your general information and use only. It is subject to
                change without notice.
              </Typography>
            </li>
            <li>
              <Typography className='text-style'>
                Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness,
                performance, completeness, or suitability of the information and materials found or offered on this
                website for any particular purpose. You acknowledge that such information and materials may contain
                inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the
                fullest extent permitted by law.
              </Typography>
            </li>
            <li>
              <Typography className='text-style'>
                Your use of any information or materials on this website is entirely at your own risk, for which we
                shall not be liable. It shall be your own responsibility to ensure that any products, services, or
                information available through this website meet your specific requirements.
              </Typography>
            </li>
            <li>
              <Typography className='text-style'>
                This website contains material that is owned by or licensed to us. This material includes, but is not
                limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in
                accordance with the copyright notice, which forms part of these terms and conditions.
              </Typography>
            </li>
            <li>
              <Typography className='text-style'>
                All trademarks reproduced on this website that are not the property of, or licensed to, the operator are
                acknowledged on the website.
              </Typography>
            </li>
            <li>
              <Typography className='text-style'>
                Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.
              </Typography>
            </li>
            <li>
              <Typography className='text-style'>
                This website includes links to other websites. These links are provided for your convenience to access
                additional information. This does not indicate that we endorse the content, resources, or services
                provided on the website(s). We have no responsibility for the content of the linked website(s) or the
                people who provide services.
              </Typography>
            </li>
            <li>
              <Typography className='text-style'>
                You may not create a link to this website from another website or document without You:Flourish P.B.C.’s
                prior written consent.
              </Typography>
            </li>
            <li>
              <Typography className='text-style'>
                Your use of this website and any dispute arising out of such use of the website are subject to the laws
                of the United States of America.
              </Typography>
            </li>
            <li>
              <Typography className='text-style'>
                You: Flourish is not designed to replace advice from healthcare or mental healthcare professionals. The
                application&rsquo;s purpose is to offer information that may support your well-being. Information shared
                by third parties should be independently evaluated. You:Flourish bears no responsibility for information
                provided by partners, including third-party sites.
              </Typography>
            </li>
          </ul>
        </div>
        <div className='big-separator' />

        {/* Privacy Policy */}
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Privacy Policy
          </Typography>
        </div>
        <Typography className='text-style'>Last modified: April 18, 2024</Typography>
        <Typography className='text-style'>Introduction</Typography>
        <Typography className='text-style'>
          You:Flourish, Inc. (“Y:F,” “Company,” “we” or “our”) respect your privacy and are committed to protecting it
          through our compliance with this policy.
        </Typography>
        <Typography className='text-style'>
          This policy describes the types of information we may collect from you or that you may provide when you visit
          the website and application located at [insert Y:F URL] (collectively, our “Application”) and our practices
          for collecting, using, maintaining, protecting, and disclosing that information.
        </Typography>
        <Typography className='text-style'>This policy applies to information we collect:</Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>On this Application.</Typography>
          </li>
          <li>
            <Typography className='text-style'>
              In email, text, and other electronic messages between you and this Application.{' '}
            </Typography>
          </li>
        </ul>
        <Typography className='text-style'>It does not apply to information collected by:</Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>
              Us offline or through any other means, including on any other website operated by Company or any third
              party; or{' '}
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              Any third party , including through any application or content (including advertising) that may link to or
              be accessible from or on the Application
            </Typography>
          </li>
        </ul>
        <Typography className='text-style'>
          Please read this policy carefully to understand our policies and practices regarding your information and how
          we will treat it. If you do not agree with our policies and practices, your choice is not to use our
          Application. By accessing or using this Application, you agree to this privacy policy. This policy may change
          from time to time (see Changes to Our Privacy Policy). Your continued use of this Application after we make
          changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
        </Typography>
        {/* Children Under the Age of 16 */}
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Children Under the Age of 16
          </Typography>
        </div>
        <Typography className='text-style'>
          Our Application is not intended for children under 16 years of age. No one under age 16 may provide any
          information to or on the Application. We do not knowingly collect personal information from children under 16.
          If you are under 16, do not use or provide any information on this Application or through any of its features,
          register on the Application, make any purchases through the Application, use any of the interactive or public
          comment features of this Application, or provide any information about yourself to us, including your name,
          address, telephone number, email address, or any screen name or user name you may use. If we learn we have
          collected or received personal information from a child under 16 without verification of parental consent, we
          will delete that information. If you believe we might have any information from or about a child under 16,
          please contact us at data@you-flourish.com.
        </Typography>
        <Typography className='text-style'>
          California residents under 16 years of age may have additional rights regarding the collection and sale of
          their personal information. Please see Your State Privacy Rights for more information.
        </Typography>

        {/* Information We Collect About You and How We Collect It */}
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Information We Collect About You and How We Collect It
          </Typography>
        </div>
        <Typography className='text-style'>
          We collect several types of information from and about users of our Application, including information:
        </Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>
              By which you may be personally identified, such as name, postal address, e-mail address, telephone number,
              or any other identifier by which you may be contacted online or offline (“personal information”);
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              That is about you but individually does not identify you, such as gender identity, sexual orientation,
              pronouns; and/or
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              About your internet connection, the equipment you use to access our Application, and usage details.
            </Typography>
          </li>
        </ul>
        <Typography className='text-style'>We collect this information: </Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>Directly from you when you provide it to us. </Typography>
          </li>
          <li>
            <Typography className='text-style'>Directly from you when you provide it to us.</Typography>
          </li>
          <li>
            <Typography className='text-style'>From third parties, for example, our business partners. </Typography>
          </li>
        </ul>

        {/* Information You Provide to Us */}
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Information You Provide to Us{' '}
          </Typography>
        </div>
        <Typography className='text-style'>
          The information we collect on or through our Application may include:
        </Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>
              Information that you provide by filling in forms on our Application. This includes information provided at
              the time of registering to use our Application, subscribing to our service, posting material, or
              requesting further services. We may also ask you for information when you enter a contest or promotion
              sponsored by us, and when you report a problem with our Application.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              Records and copies of your correspondence (including email addresses), if you contact us.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              Your responses to surveys that we might ask you to complete for research purposes.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              Your responses to surveys that we might ask you to complete for research purposes.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              Personal information, gender identity, sexual orientation, and/or pronouns.e{' '}
            </Typography>
          </li>
        </ul>
        <Typography className='text-style'>
          You also may provide information to be published or displayed (hereinafter, “posted”) on public areas of the
          Application, or transmitted to other users of the Application or third parties (collectively, “User
          Contributions”). Your User Contributions are posted on and transmitted to others at your own risk. Although,
          please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the
          actions of other users of the Application with whom you may choose to share your User Contributions.
          Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized
          persons.
        </Typography>
        {/* Information We Collect Through Automatic Data Collection Technologies */}
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Information We Collect Through Automatic Data Collection Technologies
          </Typography>
        </div>
        <Typography className='text-style'>
          As you navigate through and interact with our Application, we may use automatic data collection technologies
          to collect certain information about your equipment, browsing actions, and patterns, including:
        </Typography>

        <ul className='list-style'>
          <li>
            <Typography className='text-style'>
              Details of your visits to our Application, including traffic data, location data, logs, and other
              communication data and the resources that you access and use on the Application.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              Information about your computer and internet connection, including your IP address, operating system, and
              browser type.
            </Typography>
          </li>
        </ul>

        <Typography className='text-style'>
          The information we collect automatically may include personal information, or we may maintain it or associate
          it with personal information we collect in other ways or receive from third parties. It helps us to improve
          our Application and to deliver a better and more personalized service, including by enabling us to:{' '}
        </Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>Estimate our audience size and usage patterns.</Typography>
          </li>
          <li>
            <Typography className='text-style'>
              Store information about your preferences, allowing us to customize our Application according to your
              individual interests.{' '}
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>Speed up your searches.</Typography>
          </li>
          <li>
            <Typography className='text-style'>Recognize you when you return to our Application.</Typography>
          </li>
        </ul>

        <Typography className='text-style'>
          The technologies we use for this automatic data collection may include:
        </Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>
              <span className='bold-text'>Cookies (or browser cookies).</span> A cookie is a small file placed on the
              hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate
              setting on your browser. However, if you select this setting you may be unable to access certain parts of
              our Application. Unless you have adjusted your browser setting so that it will refuse cookies, our system
              will issue cookies when you direct your browser to our Application.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              <span className='bold-text'>Flash Cookies.</span> Certain features of our Application may use local stored
              objects (or Flash cookies) to collect and store information about your preferences and navigation to,
              from, and on our Application. Flash cookies are not managed by the same browser settings as are used for
              browser cookies. For information about managing your privacy and security settings for Flash cookies, see
              Choices About How We Use and Disclose Your Information.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              <span className='bold-text'>Web Beacons.</span> Pages of our the Application and our emails may contain
              small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel
              gifs) that permit the Company, for example, to count users who have visited those pages or opened an email
              and for other related website statistics (for example, recording the popularity of certain website content
              and verifying system and server integrity).
            </Typography>
          </li>
        </ul>
        <Typography className='text-style'>
          We collect personal information when you register for the Application or on other services provided by us, and
          we may tie this information to personal information about you that we collect from other sources or you
          provide to us.
        </Typography>
        <div className='big-separator' />

        {/*  Third-Party Use of Cookies and Other Tracking Technologies */}

        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Third-Party Use of Cookies and Other Tracking Technologies{' '}
          </Typography>
        </div>
        <Typography className='text-style'>
          Some content or applications, including advertisements, on the Application are served by third-parties,
          including advertisers, ad networks and servers, content providers, and application providers. These third
          parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect
          information about you when you use our website. The information they collect may be associated with your
          personal information or they may collect information, including personal information, about your online
          activities over time and across different websites and other online services. They may use this information to
          provide you with interest-based (behavioral) advertising or other targeted content.
        </Typography>
        <Typography className='text-style'>
          We do not control these third parties&rsquo; tracking technologies or how they may be used. If you have any
          questions about an advertisement or other targeted content, you should contact the responsible provider
          directly. For information about how you can opt out of receiving targeted advertising from many providers, see
          Choices About How We Use and Disclose Your Information.{' '}
        </Typography>

        {/*  How We Use Your Information */}
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            How We Use Your Information{' '}
          </Typography>
        </div>
        <Typography className='text-style'>
          We use information that we collect about you or that you provide to us, including any personal information:{' '}
        </Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>To present our Application and its contents to you. </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              To provide you with information, products, or services that you request from us.{' '}
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>To fulfill any other purpose for which you provide it. </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              To notify you about changes to our Application or any products or services we offer or provide though it.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              To allow you to participate in interactive features on our Application.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              In any other way we may describe when you provide the information.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>For any other purpose with your consent.</Typography>
          </li>
        </ul>
        <Typography className='text-style'>
          We may use the information we have collected from you to enable us to display advertisements to our
          advertisers&rsquo; target audiences. Even though we do not disclose your personal information for these
          purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may
          assume that you meet its target criteria.
        </Typography>

        {/*  Disclosure of Your Information */}
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Disclosure of Your Information
          </Typography>
        </div>
        <Typography className='text-style'>
          We may disclose aggregated information about our users, and information that does not identify any individual,
          without restriction.{' '}
        </Typography>
        <Typography className='text-style'>
          We may disclose personal information that we collect or you provide as described in this privacy policy:{' '}
        </Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>To our subsidiaries and affiliates.</Typography>
          </li>
          <li>
            <Typography className='text-style'>
              To contractors, service providers, and other third parties we use to support our business and who are
              bound by contractual obligations to keep personal information confidential and use it only for the
              purposes for which we disclose it to them.{' '}
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
              dissolution, or other sale or transfer of some or all of Y:F&rsquo;s assets, whether as a going concern or
              as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Y:F about
              our Application users is among the assets transferred.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              To fulfill the purpose for which you provide it. For example, if you give us an email address to use the
              &ldquo;email a friend&rdquo; feature of our Application, we will transmit the contents of that email and
              your email address to the recipients.{' '}
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              For any other purpose disclosed by us when you provide the information.{' '}
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>With your consent. </Typography>
          </li>
          <li>
            <Typography className='text-style'>For any other purpose with your consent.</Typography>
          </li>
        </ul>
        <Typography className='text-style'>We may also disclose your personal information:</Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>
              To comply with any court order, law, or legal process, including to respond to any government or
              regulatory request.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              To enforce or apply our terms of use [INSERT AS LINK TO WEBSITE&rsquo;S TERMS OF USE] and other
              agreements, including for billing and collection purposes.
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Y:F,
              our customers, or others. This includes exchanging information with other companies and organizations for
              the purposes of fraud protection and credit risk reduction.
            </Typography>
          </li>
        </ul>

        {/*  Choices About How We Use and Disclose Your Information */}
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Choices About How We Use and Disclose Your Information
          </Typography>
        </div>
        <Typography className='text-style'>
          We strive to provide you with choices regarding the personal information you provide to us. We have created
          mechanisms to provide you with the following control over your information:{' '}
        </Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>
              Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or
              to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of
              this site may then be inaccessible or not function properly.
            </Typography>
          </li>
        </ul>
        <Typography className='text-style'>
          We do not control third parties&rsquo; collection or use of your information to serve interest-based
          advertising. However these third parties may provide you with ways to choose not to have your information
          collected or used in this way. You can opt out of receiving targeted ads from members of the Network
          Advertising Initiative (&ldquo;NAI&rdquo;) on the NAI&rsquo;s website.{' '}
        </Typography>
        <Typography className='text-style'>
          California residents may have additional personal information rights and choices. Please see Your California
          Privacy Rights (below) for more information.{' '}
        </Typography>
        <Typography className='text-style'>
          Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may
          submit a request to this designated address: data@you-flourish.com. However, please know we do not currently
          sell data triggering that statute&rsquo;s opt-out requirements.{' '}
        </Typography>
        <Typography className='text-style'>Accessing and Correcting Your Information </Typography>
        <Typography className='text-style'>
          You can review and change your personal information by logging into the Application and visiting your account
          profile page.{' '}
        </Typography>
        <Typography className='text-style'>
          If you delete your User Contributions from the Application, copies of your User Contributions may remain
          viewable in cached and archived pages, or might have been copied or stored by other Application users. Proper
          access and use of information provided on the Application, including User Contributions, is governed by our
          terms of use [URL LINK TO TERMS OF USE].
        </Typography>
        <Typography className='text-style'>
          California and other state residents may have additional personal information rights and choices. Please see
          Your State Privacy Rights (below) for more information.{' '}
        </Typography>
        <Typography className='text-style'>Your State Privacy Rights</Typography>
        <Typography className='text-style'>
          If you are a California resident, California law may provide you with additional rights regarding our use of
          your personal information. To learn more about your California privacy rights, visit
          https://oag.ca.gov/privacy/ccpa and https://cppa.ca.gov/regulations/.
        </Typography>
        <Typography className='text-style'>
          California&rsquo;s &ldquo;Shine the Light&rdquo; law (Civil Code Section § 1798.83) permits users of our
          Application that are California residents to request certain information regarding our disclosure of personal
          information to third parties for their direct marketing purposes. To make such a request, please send an email
          to data@you-flourish.com.{' '}
        </Typography>
        <Typography className='text-style'>
          Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Montana, Oregon, Tennessee, Texas, Utah, and Virginia
          provide (now or in the future) their state residents with rights to:{' '}
        </Typography>
        <ul className='list-style'>
          <li>
            <Typography className='text-style'>Confirm whether we process their personal information.</Typography>
          </li>
          <li>
            <Typography className='text-style'>Access and delete certain personal information. </Typography>
          </li>
          <li>
            <Typography className='text-style'>
              Correct inaccuracies in their personal information, taking into account the information&rsquo;s nature
              processing purpose (excluding Iowa and Utah).{' '}
            </Typography>
          </li>
          <li>
            <Typography className='text-style'>Data portability.</Typography>
          </li>
          <li>
            <Typography className='text-style'>Opt-out of personal data processing for:.</Typography>
          </li>
          <ul className='list-style'>
            <li>
              <Typography className='text-style'>targeted advertising (excluding Iowa);</Typography>
            </li>
            <ul className='list-style'>
              <li>
                <Typography className='text-style'>sales; or</Typography>
              </li>
              <li>
                <Typography className='text-style'>
                  profiling in furtherance of decisions that produce legal or similarly significant effects (excluding
                  Iowa and Utah).
                </Typography>
              </li>
            </ul>
            <li>
              <Typography className='text-style'>
                Either limit (opt-out of) or require consent to process sensitive personal data.
              </Typography>
            </li>
          </ul>
        </ul>

        <Typography className='text-style'>
          The exact scope of these rights may vary by state. To exercise any of these rights please email
          data@you-flourish.com. To appeal a decision regarding a consumer rights request please email
          data@you-flourish.com.{' '}
        </Typography>
        <Typography className='text-style'>
          Nevada provides its residents with a limited right to opt-out of certain personal information sales. Residents
          who wish to exercise this sale opt-out rights may submit a request to this designated address:
          data@you-flourish.com. However, please know we do not currently sell data triggering that statute&rsquo;s
          opt-out requirements.{' '}
        </Typography>
        <div className='big-separator' />

        {/*  Data Security */}
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Data Security
          </Typography>
        </div>
        <Typography className='text-style'>
          We have implemented measures designed to secure your personal information from accidental loss and from
          unauthorized access, use, alteration, and disclosure.{' '}
        </Typography>
        <Typography className='text-style'>
          The safety and security of your information also depends on you. Where we have given you (or where you have
          chosen) a password for access to certain parts of our Application, you are responsible for keeping this
          password confidential. We ask you not to share your password with anyone. We urge you to be careful about
          giving out information in public areas of the Application like message boards. The information you share in
          public areas may be viewed by any user of the Application.{' '}
        </Typography>
        <Typography className='text-style'>
          Unfortunately, the transmission of information via the internet is not completely secure. Although we do our
          best to protect your personal information, we cannot guarantee the security of your personal information
          transmitted to our Application. Any transmission of personal information is at your own risk. We are not
          responsible for circumvention of any privacy settings or security measures contained on the Application.{' '}
        </Typography>
        <div className='big-separator' />

        {/*  Changes to Our Privacy Policy */}
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Changes to Our Privacy Policy
          </Typography>
        </div>

        <Typography className='text-style'>
          It is our policy to post any changes we make to our privacy policy on this page with a notice that the privacy
          policy has been updated on the Application home page. If we make material changes to how we treat our
          users&rsquo; personal information, we will notify you through a notice on the Application home page. The date
          the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we
          have an up-to-date active and deliverable email address for you, and for periodically visiting our Application
          and this privacy policy to check for any changes{' '}
        </Typography>
        <div className='big-separator' />

        {/*  Contact Information */}
        <div className='sub-title'>
          <Typography variant='h3' color={Colors.Primary} fontFamily={FontFamily.Secondary} fontSize={FontSize.FontLG}>
            Contact Information
          </Typography>
        </div>
        <Typography className='text-style'>
          To ask questions or comment about this privacy policy and our privacy practices, contact us at:{' '}
        </Typography>
        <Typography className='text-style'>303 E 17th Ave</Typography>
        <Typography className='text-style'>Fourth floor</Typography>
        <Typography className='text-style'>Denver, CO 80203</Typography>
        <Typography className='text-style'>or</Typography>
        <Typography className='text-style'>data@you-flourish.com</Typography>
        <Typography className='text-style'>
          To register a complaint or concern, please reach out to the addresses above.
        </Typography>
      </div>
    </STerms>
  );
};

export default Terms;
