import styled from 'styled-components';
import Tabs from '@mui/material/Tabs';
import { FontFamily, FontSize, FontWeight } from 'styles/variables';

export const STabs = styled(Tabs)`
  &.MuiTabs-root {
    .MuiTab-root {
      color: ${({ color }) => color || ''};
      font-size: ${FontSize.FontMLG};
      font-weight: ${FontWeight.Bold};
      font-family: ${FontFamily.Secondary};
      text-transform: none;

      &:not(.Mui-selected) {
        color: ${({ color }) => `${color}B3` || ''};
      }
    }

    .MuiTabs-scroller {
      overflow: auto !important;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .MuiTabs-indicator {
      background: ${({ color }) => color || ''};
      height: 4px;
    }
  }
`;
