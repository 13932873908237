export enum Colors {
  Primary = '#1C75BC',
  Secondary = '#F7941D',
  Error = '#ED1C24',
  TextPrimary = '#272524',
  TextSecondary = '#383838',
  Black = '#000000',
  White = '#FFFFFF',
  Disabled = '#BEBEBE',
  Red = '#DB3831',
  Orange = '#E99A3F',
  Yellow = '#FADF4F',
  Green = '#479F55',
  GreenDark = '#00A14B',
  Blue = '#3972B7',
  BlueLink = '#1B75BC',
  Purple = '#7F3F98',
  Gray = '#CACBCB',
  GrayDark = '#888787',
  GrayLight = '#EBEBEB',
  GrayLabel = '#969696',
  GrayHelper = '#777978',
  GrayText = '#676A6C',
  GrayBorder = '#E1DFDF',
  GrayBackground = '#C5C5C5B2',
  Gray1 = '#979797',
  Gray2 = '#818181',
  PrimaryLight = '#1C75BC22',
}

export enum FontFamily {
  Primary = 'Comfortaa, sans-serif',
  Secondary = 'Open Sans, sans-serif',
}

export enum FontSize {
  FontXXS = '10px',
  FontXS = '12px',
  FontSM = '14px',
  FontSMD = '15px',
  FontMD = '16px',
  FontMMD = '17px',
  FontMLG = '18px',
  FontLG = '20px',
  FontXL = '24px',
  FontXXL = '32px',
  FontXXXL = '48px',
}

export enum FontWeight {
  Light = 300,
  Regular = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
}

export enum Breakpoint {
  ExtraSmall = '400px',
  Small = '600px',
  Medium = '900px',
  Large = '1200px',
}
