import { TSelectOption } from 'types/global';
import { RoutePaths } from '../routes/Routes.types';

export enum PronounsEnum {
  'She/Her' = 1,
  'He/Him',
  'They/Them',
  'Ze/Zir/Zirs',
  'Fae/Faer/Faers',
  'Xe/Xem/Xir',
  'Any Pronoun(s)',
  'Prefer not to say',
}

export const customPronounceId = 9;
export const customGenderIdentityId = 16;
export const customSexualOrientationId = 11;
export const customUserRaceId = 9;

export const phoneNumberRegExp = /^(\d{3}-\d{3}-\d{4})$/;

export const pronounsOptions: TSelectOption[] = [
  { value: 0, label: 'She/Her' },
  { value: 1, label: 'He/Him' },
  { value: 2, label: 'They/Them' },
  { value: 3, label: 'She/They' },
  { value: 4, label: 'He/They' },
  { value: 5, label: 'Ze/Zir/Zirs' },
  { value: 6, label: 'Fae/Faer/Faers' },
  { value: 7, label: 'Xe/Xem/Xir' },
  { value: 8, label: 'Any Pronoun(s)' },
  { value: 9, label: 'Prefer to self describe:' },
];

export const genderIdentityOptions: TSelectOption[] = [
  { value: 1, label: 'Cisgender man' },
  { value: 2, label: 'Cisgender woman' },
  { value: 3, label: 'Trans man, transmasculine, or FTM' },
  { value: 4, label: 'Transwoman, transfeminine, or MTF' },
  { value: 5, label: 'Non-binary or Gender non-conforming/fluid/queer' },
  { value: 6, label: 'Two-spirit or Third gender' },
  { value: 7, label: 'Omnigender or pangender' },
  { value: 8, label: 'Intersex' },
  { value: 9, label: 'Agender' },
  { value: 10, label: 'Prefer to self describe:' },
];

export const sexualOrientationOptions: TSelectOption[] = [
  { value: 1, label: 'Heterosexual' },
  { value: 2, label: 'Lesbian' },
  { value: 3, label: 'Gay' },
  { value: 4, label: 'Bisexual' },
  { value: 5, label: 'Asexual' },
  { value: 6, label: 'Queer' },
  { value: 7, label: 'Pansexual' },
  { value: 8, label: 'Prefer to self describe:' },
];

export const ageGroupOptions: TSelectOption[] = [
  { value: 1, label: '16-17' },
  { value: 2, label: '18-26' },
  { value: 3, label: '27-34' },
  { value: 4, label: '35-44' },
  { value: 5, label: '45-54' },
  { value: 6, label: '55-64' },
  { value: 7, label: '65+' },
];

export const primarySpecialties: TSelectOption[] = [
  { value: 1, label: 'Allied Healthcare' },
  { value: 2, label: 'Behavioral/Mental Health' },
  { value: 3, label: 'Complementary/Alternative Health' },
  { value: 4, label: 'Dental Health' },
  { value: 5, label: 'Primary Care' },
  { value: 6, label: 'Specialist' },
];

export enum PrimarySpecialtiesEnum {
  'Allied Healthcare' = 1,
  'Behavioral/Mental Health' = 2,
  'Complementary/Alternative Health' = 3,
  'Dental Health' = 4,
  'Primary Care' = 5,
  'Specialist' = 6,
}

export const specialityIdentityOptions: TSelectOption[] = [
  { value: 1, label: 'Lesbian' },
  { value: 2, label: 'Gay' },
  { value: 3, label: 'Gay' },
  { value: 4, label: 'Bisexual' },
  { value: 5, label: 'Transgender' },
  { value: 6, label: 'Non-binary or Gender non-conforming/fluid/queer' },
];

export const languageOptions: TSelectOption[] = [
  { value: 1, label: 'English' },
  { value: 2, label: 'Chinese' },
  { value: 3, label: 'Hindi' },
  { value: 4, label: 'Spanish' },
  { value: 5, label: 'French' },
  { value: 6, label: 'Arabic' },
];

export const states: TSelectOption[] = [
  { value: 'Alabama', label: 'Alabama' },
  { value: 'Alaska', label: 'Alaska' },
  { value: 'Arizona', label: 'Arizona' },
  { value: 'Arkansas', label: 'Arkansas' },
  { value: 'California', label: 'California' },
  { value: 'Colorado', label: 'Colorado' },
  { value: 'Connecticut', label: 'Connecticut' },
  { value: 'Delaware', label: 'Delaware' },
  { value: 'Florida', label: 'Florida' },
  { value: 'Georgia', label: 'Georgia' },
  { value: 'Hawaii', label: 'Hawaii' },
  { value: 'Idaho', label: 'Idaho' },
  { value: 'Illinois', label: 'Illinois' },
  { value: 'Indiana', label: 'Indiana' },
  { value: 'Iowa', label: 'Iowa' },
  { value: 'Kansas', label: 'Kansas' },
  { value: 'Kentucky', label: 'Kentucky' },
  { value: 'Louisiana', label: 'Louisiana' },
  { value: 'Maine', label: 'Maine' },
  { value: 'Maryland', label: 'Maryland' },
  { value: 'Massachusetts', label: 'Massachusetts' },
  { value: 'Michigan', label: 'Michigan' },
  { value: 'Minnesota', label: 'Minnesota' },
  { value: 'Mississippi', label: 'Mississippi' },
  { value: 'Missouri', label: 'Missouri' },
  { value: 'Montana', label: 'Montana' },
  { value: 'Nebraska', label: 'Nebraska' },
  { value: 'Nevada', label: 'Nevada' },
  { value: 'New Hampshire', label: 'New Hampshire' },
  { value: 'New Jersey', label: 'New Jersey' },
  { value: 'New Mexico', label: 'New Mexico' },
  { value: 'New York', label: 'New York' },
  { value: 'North Carolina', label: 'North Carolina' },
  { value: 'North Dakota', label: 'North Dakota' },
  { value: 'Ohio', label: 'Ohio' },
  { value: 'Oklahoma', label: 'Oklahoma' },
  { value: 'Oregon', label: 'Oregon' },
  { value: 'Pennsylvania', label: 'Pennsylvania' },
  { value: 'Rhode Island', label: 'Rhode Island' },
  { value: 'South Carolina', label: 'South Carolina' },
  { value: 'South Dakota', label: 'South Dakota' },
  { value: 'Tennessee', label: 'Tennessee' },
  { value: 'Texas', label: 'Texas' },
  { value: 'Utah', label: 'Utah' },
  { value: 'Vermont', label: 'Vermont' },
  { value: 'Virginia', label: 'Virginia' },
  { value: 'Washington', label: 'Washington' },
  { value: 'West Virginia', label: 'West Virginia' },
  { value: 'Wisconsin', label: 'Wisconsin' },
  { value: 'Wyoming', label: 'Wyoming' },
];

export const countries: TSelectOption[] = [
  { label: 'United States', value: 'United States' },
  { label: 'Afghanistan', value: 'Afghanistan' },
  { label: 'Albania', value: 'Albania' },
  { label: 'Algeria', value: 'Algeria' },
  { label: 'Andorra', value: 'Andorra' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
  { label: 'Argentina', value: 'Argentina' },
  { label: 'Armenia', value: 'Armenia' },
  { label: 'Australia', value: 'Australia' },
  { label: 'Austria', value: 'Austria' },
  { label: 'Azerbaijan', value: 'Azerbaijan' },
  { label: 'Bahamas', value: 'Bahamas' },
  { label: 'Bahrain', value: 'Bahrain' },
  { label: 'Bangladesh', value: 'Bangladesh' },
  { label: 'Barbados', value: 'Barbados' },
  { label: 'Belarus', value: 'Belarus' },
  { label: 'Belgium', value: 'Belgium' },
  { label: 'Belize', value: 'Belize' },
  { label: 'Benin', value: 'Benin' },
  { label: 'Bhutan', value: 'Bhutan' },
  { label: 'Bolivia', value: 'Bolivia' },
  { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'Brazil', value: 'Brazil' },
  { label: 'Brunei', value: 'Brunei' },
  { label: 'Bulgaria', value: 'Bulgaria' },
  { label: 'Burkina Faso', value: 'Burkina Faso' },
  { label: 'Burundi', value: 'Burundi' },
  { label: 'Cabo Verde', value: 'Cabo' },
  { label: 'Cambodia', value: 'Cambodia' },
  { label: 'Cameroon', value: 'Cameroon' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Central African Republic', value: 'Central African Republic' },
  { label: 'Chad', value: 'Chad' },
  { label: 'Chile', value: 'Chile' },
  { label: 'China', value: 'China' },
  { label: 'Colombia', value: 'Colombia' },
  { label: 'Comoros', value: 'Comoros' },
  { label: 'Congo', value: 'Congo' },
  { label: 'Costa Rica', value: 'Costa' },
  { label: 'Croatia', value: 'Croatia' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Cyprus', value: 'Cyprus' },
  { label: 'Czech Republic', value: 'Czech Republic' },
  { label: 'Democratic Republic of the Congo', value: 'Democratic Republic of the Congo' },
  { label: 'Denmark', value: 'Denmark' },
  { label: 'Djibouti', value: 'Djibouti' },
  { label: 'Dominica', value: 'Dominica' },
  { label: 'Dominican Republic', value: 'Dominican Republic' },
  { label: 'Ecuador', value: 'Ecuador' },
  { label: 'Egypt', value: 'Egypt' },
  { label: 'El Salvador', value: 'El Salvador' },
  { label: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { label: 'Eritrea', value: 'Eritrea' },
  { label: 'Estonia', value: 'Estonia' },
  { label: 'Eswatini', value: 'Eswatini' },
  { label: 'Ethiopia', value: 'Ethiopia' },
  { label: 'Fiji', value: 'Fiji' },
  { label: 'Finland', value: 'Finland' },
  { label: 'France', value: 'France' },
  { label: 'Gabon', value: 'Gabon' },
  { label: 'Gambia', value: 'Gambia' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Germany', value: 'Germany' },
  { label: 'Ghana', value: 'Ghana' },
  { label: 'Greece', value: 'Greece' },
  { label: 'Grenada', value: 'Grenada' },
  { label: 'Guatemala', value: 'Guatemala' },
  { label: 'Guinea', value: 'Guinea' },
  { label: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { label: 'Guyana', value: 'Guyana' },
  { label: 'Haiti', value: 'Haiti' },
  { label: 'Honduras', value: 'Honduras' },
  { label: 'Hungary', value: 'Hungary' },
  { label: 'Iceland', value: 'Iceland' },
  { label: 'India', value: 'India' },
  { label: 'Indonesia', value: 'Indonesia' },
  { label: 'Iran', value: 'Iran' },
  { label: 'Iraq', value: 'Iraq' },
  { label: 'Ireland', value: 'Ireland' },
  { label: 'Israel', value: 'Israel' },
  { label: 'Italy', value: 'Italy' },
  { label: 'Ivory Coast', value: 'Ivory' },
  { label: 'Jamaica', value: 'Jamaica' },
  { label: 'Japan', value: 'Japan' },
  { label: 'Jordan', value: 'Jordan' },
  { label: 'Kazakhstan', value: 'Kazakhstan' },
  { label: 'Kenya', value: 'Kenya' },
  { label: 'Kiribati', value: 'Kiribati' },
  { label: 'Kosovo', value: 'Kosovo' },
  { label: 'Kuwait', value: 'Kuwait' },
  { label: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { label: 'Laos', value: 'Laos' },
  { label: 'Latvia', value: 'Latvia' },
  { label: 'Lebanon', value: 'Lebanon' },
  { label: 'Lesotho', value: 'Lesotho' },
  { label: 'Liberia', value: 'Liberia' },
  { label: 'Libya', value: 'Libya' },
  { label: 'Liechtenstein', value: 'Liechtenstein' },
  { label: 'Lithuania', value: 'Lithuania' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Madagascar', value: 'Madagascar' },
  { label: 'Malawi', value: 'Malawi' },
  { label: 'Malaysia', value: 'Malaysia' },
  { label: 'Maldives', value: 'Maldives' },
  { label: 'Mali', value: 'Mali' },
  { label: 'Malta', value: 'Malta' },
  { label: 'Marshall Islands', value: 'Marshall Islands' },
  { label: 'Mauritania', value: 'Mauritania' },
  { label: 'Mauritius', value: 'Mauritius' },
  { label: 'Mexico', value: 'Mexico' },
  { label: 'Micronesia', value: 'Micronesia' },
  { label: 'Moldova', value: 'Moldova' },
  { label: 'Monaco', value: 'Monaco' },
  { label: 'Mongolia', value: 'Mongolia' },
  { label: 'Montenegro', value: 'Montenegro' },
  { label: 'Morocco', value: 'Morocco' },
  { label: 'Mozambique', value: 'Mozambique' },
  { label: 'Myanmar', value: 'Myanmar' },
  { label: 'Namibia', value: 'Namibia' },
  { label: 'Nauru', value: 'Nauru' },
  { label: 'Nepal', value: 'Nepal' },
  { label: 'Netherlands', value: 'Netherlands' },
  { label: 'New Zealand', value: 'New Zealand' },
  { label: 'Nicaragua', value: 'Nicaragua' },
  { label: 'Niger', value: 'Niger' },
  { label: 'Nigeria', value: 'Nigeria' },
  { label: 'North Korea', value: 'North Korea' },
  { label: 'North Macedonia', value: 'North Macedonia' },
  { label: 'Norway', value: 'Norway' },
  { label: 'Oman', value: 'Oman' },
  { label: 'Pakistan', value: 'Pakistan' },
  { label: 'Palau', value: 'Palau' },
  { label: 'Palestine', value: 'Palestine' },
  { label: 'Panama', value: 'Panama' },
  { label: 'Papua New Guinea', value: 'Papua New Guinea' },
  { label: 'Paraguay', value: 'Paraguay' },
  { label: 'Peru', value: 'Peru' },
  { label: 'Philippines', value: 'Philippines' },
  { label: 'Poland', value: 'Poland' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'Qatar', value: 'Qatar' },
  { label: 'Romania', value: 'Romania' },
  { label: 'Russia', value: 'Russia' },
  { label: 'Rwanda', value: 'Rwanda' },
  { label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
  { label: 'Saint Lucia', value: 'Saint Lucia' },
  { label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
  { label: 'Samoa', value: 'Samoa' },
  { label: 'San Marino', value: 'San Marino' },
  { label: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
  { label: 'Saudi Arabia', value: 'Saudi' },
  { label: 'Senegal', value: 'Senegal' },
  { label: 'Serbia', value: 'Serbia' },
  { label: 'Seychelles', value: 'Seychelles' },
  { label: 'Sierra Leone', value: 'Sierra Leone' },
  { label: 'Singapore', value: 'Singapore' },
  { label: 'Slovakia', value: 'Slovakia' },
  { label: 'Slovenia', value: 'Slovenia' },
  { label: 'Solomon Islands', value: 'Solomon Islands' },
  { label: 'Somalia', value: 'Somalia' },
  { label: 'South Africa', value: 'South Africa' },
  { label: 'South Korea', value: 'South Korea' },
  { label: 'South Sudan', value: 'South Sudan' },
  { label: 'Spain', value: 'Spain' },
  { label: 'Sri Lanka', value: 'Sri Lanka' },
  { label: 'Sudan', value: 'Sudan' },
  { label: 'Suriname', value: 'Suriname' },
  { label: 'Sweden', value: 'Sweden' },
  { label: 'Switzerland', value: 'Switzerland' },
  { label: 'Syria', value: 'Syria' },
  { label: 'Tajikistan', value: 'Tajikistan' },
  { label: 'Tanzania', value: 'Tanzania' },
  { label: 'Thailand', value: 'Thailand' },
  { label: 'Timor-Leste', value: 'Timor-Leste' },
  { label: 'Togo', value: 'Togo' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  { label: 'Tunisia', value: 'Tunisia' },
  { label: 'Turkey', value: 'Turkey' },
  { label: 'Turkmenistan', value: 'Turkmenistan' },
  { label: 'Tuvalu', value: 'Tuvalu' },
  { label: 'Uganda', value: 'Uganda' },
  { label: 'Ukraine', value: 'Ukraine' },
  { label: 'United Arab Emirates', value: 'United Arab Emirates' },
  { label: 'United Kingdom', value: 'United Kingdom' },
  { label: 'Uruguay', value: 'Uruguay' },
  { label: 'Uzbekistan', value: 'Uzbekistan' },
  { label: 'Vanuatu', value: 'Vanuatu' },
  { label: 'Vatican City', value: 'Vatican City' },
  { label: 'Venezuela', value: 'Venezuela' },
  { label: 'Vietnam', value: 'Vietnam' },
  { label: 'Yemen', value: 'Yemen' },
  { label: 'Zambia', value: 'Zambia' },
  { label: 'Zimbabwe', value: 'Zimbabwe' },
];

// export const states: TSelectOption[] = [
//   { value: 'AL', label: 'Alabama' },
//   { value: 'AK', label: 'Alaska' },
//   { value: 'AZ', label: 'Arizona' },
//   { value: 'AR', label: 'Arkansas' },
//   { value: 'CA', label: 'California' },
//   { value: 'CO', label: 'Colorado' },
//   { value: 'CT', label: 'Connecticut' },
//   { value: 'DE', label: 'Delaware' },
//   { value: 'FL', label: 'Florida' },
//   { value: 'GA', label: 'Georgia' },
//   { value: 'HI', label: 'Hawaii' },
//   { value: 'ID', label: 'Idaho' },
//   { value: 'IL', label: 'Illinois' },
//   { value: 'IN', label: 'Indiana' },
//   { value: 'IA', label: 'Iowa' },
//   { value: 'KS', label: 'Kansas' },
//   { value: 'KY', label: 'Kentucky' },
//   { value: 'LA', label: 'Louisiana' },
//   { value: 'ME', label: 'Maine' },
//   { value: 'MD', label: 'Maryland' },
//   { value: 'MA', label: 'Massachusetts' },
//   { value: 'MI', label: 'Michigan' },
//   { value: 'MN', label: 'Minnesota' },
//   { value: 'MS', label: 'Mississippi' },
//   { value: 'MO', label: 'Missouri' },
//   { value: 'MT', label: 'Montana' },
//   { value: 'NE', label: 'Nebraska' },
//   { value: 'NV', label: 'Nevada' },
//   { value: 'NH', label: 'New Hampshire' },
//   { value: 'NJ', label: 'New Jersey' },
//   { value: 'NM', label: 'New Mexico' },
//   { value: 'NY', label: 'New York' },
//   { value: 'NC', label: 'North Carolina' },
//   { value: 'ND', label: 'North Dakota' },
//   { value: 'OH', label: 'Ohio' },
//   { value: 'OK', label: 'Oklahoma' },
//   { value: 'OR', label: 'Oregon' },
//   { value: 'PA', label: 'Pennsylvania' },
//   { value: 'RI', label: 'Rhode Island' },
//   { value: 'SC', label: 'South Carolina' },
//   { value: 'SD', label: 'South Dakota' },
//   { value: 'TN', label: 'Tennessee' },
//   { value: 'TX', label: 'Texas' },
//   { value: 'UT', label: 'Utah' },
//   { value: 'VT', label: 'Vermont' },
//   { value: 'VA', label: 'Virginia' },
//   { value: 'WA', label: 'Washington' },
//   { value: 'WV', label: 'West Virginia' },
//   { value: 'WI', label: 'Wisconsin' },
//   { value: 'WY', label: 'Wyoming' },
// ];

export const paymentOptions: TSelectOption[] = [
  { value: 1, label: 'Free Services' },
  { value: 2, label: 'Sliding Scale' },
  { value: 3, label: 'Self-Pay' },
  { value: 4, label: 'Aetna' },
  { value: 5, label: 'Anthem' },
  { value: 6, label: 'Blue Cross Blue Shield' },
  { value: 7, label: 'Centene' },
  { value: 8, label: 'Cigna' },
  { value: 9, label: 'CVS Health' },
  { value: 10, label: 'GuideWell' },
  { value: 11, label: 'HCSC' },
  { value: 12, label: 'Humana' },
  { value: 13, label: 'Kaiser Permanente' },
  { value: 14, label: 'Medicaid' },
  { value: 15, label: 'Medicare' },
  { value: 16, label: 'Molina' },
  { value: 17, label: 'United Healthcare' },
];

export const PrivacyHyperlinks = {
  privacyPolicy: 'https://you-flourish.com/privacy-policy/',
  termsAndConditions: `${`${window.location.protocol}//${window.location.host}`}/app/${RoutePaths.TERMS}`,
  termsAndConditionsExternal: `${`${window.location.protocol}//${window.location.host}`}/${RoutePaths.TERMS}`,
  dataPolicy: '',
  cookiePolicy: '',
  aboutUs: 'https://you-flourish.com/',
};
