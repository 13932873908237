import { immer } from 'zustand/middleware/immer';
import { create } from 'zustand';

import { IResultResponse, NonFunctionKeys, Status } from 'models';
import {
  Races,
  License,
  AgeGroup,
  Pronounce,
  Language,
  Specialities,
  PaymentOption,
  LGBTQIdentity,
  GenderIdentity,
  SexualOrientations,
} from 'models/global';
import {
  getLicenses,
  getAgeGroups,
  getLanguages,
  getPronounces,
  getUserRaces,
  getSpecialties,
  getPaymentOptions,
  getLGBTQIdentities,
  getGenderIdentities,
  getSexualOrientations,
} from 'api/global';
import { TSelectOption } from 'types/global';

interface Store {
  getPronounces: () => Promise<IResultResponse<Pronounce[]>>;
  pronounces: TSelectOption[];
  getPronouncesStatus: Status;

  getGenderIdentities: () => Promise<IResultResponse<GenderIdentity[]>>;
  genderIdentities: TSelectOption[];
  getGenderIdentitiesStatus: Status;

  getSexualOrientations: () => Promise<IResultResponse<SexualOrientations[]>>;
  sexualOrientations: TSelectOption[];
  getSexualOrientationsStatus: Status;

  getUserRaces: () => Promise<IResultResponse<Races[]>>;
  userRaces: TSelectOption[];
  getUserRacesStatus: Status;

  getAgeGroups: () => Promise<IResultResponse<AgeGroup[]>>;
  ageGroups: TSelectOption[];
  getAgeGroupsStatus: Status;

  getLGBTQIdentities: () => Promise<IResultResponse<LGBTQIdentity[]>>;
  LGBTQIdentities: TSelectOption[];
  getLGBTQIdentitiesStatus: Status;

  getLanguages: () => Promise<IResultResponse<Language[]>>;
  languages: TSelectOption[];
  getLanguagesStatus: Status;

  getPaymentOptions: () => Promise<IResultResponse<PaymentOption[]>>;
  paymentOptions: TSelectOption[];
  getPaymentOptionsStatus: Status;

  getSpecialties: () => Promise<IResultResponse<Specialities[]>>;
  specialties: TSelectOption[];
  getSpecialtiesStatus: Status;

  getLicenses: () => Promise<IResultResponse<License[]>>;
  licenses: TSelectOption[];
  getLicensesStatus: Status;

  reset: () => void;
}

const initialState: Record<NonFunctionKeys<Store>, any> = {
  getPronouncesStatus: 'IDLE',
  pronounces: [],

  getGenderIdentitiesStatus: 'IDLE',
  genderIdentities: [],

  getSexualOrientationsStatus: 'IDLE',
  sexualOrientations: [],

  getUserRacesStatus: 'IDLE',
  userRaces: [],

  getAgeGroupsStatus: 'IDLE',
  ageGroups: [],

  getLGBTQIdentitiesStatus: 'IDLE',
  LGBTQIdentities: [],

  getLanguagesStatus: 'IDLE',
  languages: [],

  getPaymentOptionsStatus: 'IDLE',
  paymentOptions: [],

  getSpecialtiesStatus: 'IDLE',
  specialties: [],

  getLicensesStatus: 'IDLE',
  licenses: [],
};

export const useGlobalStore = create(
  immer<Store>((set) => ({
    ...initialState,
    getPronounces: async () => {
      try {
        set({ getPronouncesStatus: 'LOADING' });
        const result = await getPronounces();
        const updatedPronounces = result.result.map((item) => ({ label: item.name, value: item.id }));
        set({ pronounces: updatedPronounces });
        set({ getPronouncesStatus: 'SUCCESS' });

        return result;
      } catch (e) {
        set({ getPronouncesStatus: 'FAIL' });
        return Promise.reject(e);
      }
    },
    getGenderIdentities: async () => {
      try {
        set({ getGenderIdentitiesStatus: 'LOADING' });
        const result = await getGenderIdentities();
        const updatedGenderIdentities = result.result.map((item) => ({ label: item.name, value: item.id }));
        set({ genderIdentities: updatedGenderIdentities });
        set({ getGenderIdentitiesStatus: 'SUCCESS' });

        return result;
      } catch (e) {
        set({ getGenderIdentitiesStatus: 'FAIL' });
        return Promise.reject(e);
      }
    },
    getSexualOrientations: async () => {
      try {
        set({ getSexualOrientationsStatus: 'LOADING' });
        const result = await getSexualOrientations();
        const updatedSexualOrientations = result.result.map((item) => ({ label: item.name, value: item.id }));
        set({ sexualOrientations: updatedSexualOrientations });
        set({ getSexualOrientationsStatus: 'SUCCESS' });

        return result;
      } catch (e) {
        set({ getSexualOrientationsStatus: 'FAIL' });
        return Promise.reject(e);
      }
    },
    getUserRaces: async () => {
      try {
        set({ getUserRacesStatus: 'LOADING' });
        const result = await getUserRaces();
        const updatedRaces = result.result.map((item) => ({ label: item.name, value: item.id }));
        set({ userRaces: updatedRaces });
        set({ getUserRacesStatus: 'SUCCESS' });

        return result;
      } catch (e) {
        set({ getUserRacesStatus: 'FAIL' });
        return Promise.reject(e);
      }
    },
    getAgeGroups: async () => {
      try {
        set({ getAgeGroupsStatus: 'LOADING' });
        const result = await getAgeGroups();
        const updatedAgeGroups = result.result.map((item) => ({ label: item.name, value: item.id }));
        set({ ageGroups: updatedAgeGroups });
        set({ getAgeGroupsStatus: 'SUCCESS' });
        return result;
      } catch (e) {
        set({ getAgeGroupsStatus: 'FAIL' });
        return Promise.reject(e);
      }
    },
    getLGBTQIdentities: async () => {
      try {
        set({ getLGBTQIdentitiesStatus: 'LOADING' });
        const result = await getLGBTQIdentities();
        const updatedLGBTQIdentities = result.result.map((item) => ({ label: item.name, value: item.id }));
        set({ LGBTQIdentities: updatedLGBTQIdentities });
        set({ getLGBTQIdentitiesStatus: 'SUCCESS' });
        return result;
      } catch (e) {
        set({ getLGBTQIdentitiesStatus: 'FAIL' });
        return Promise.reject(e);
      }
    },
    getLanguages: async () => {
      try {
        set({ getLanguagesStatus: 'LOADING' });
        const result = await getLanguages();
        const updatedLanguages = result.result.map((item) => ({ label: item.name, value: item.id }));
        set({ languages: updatedLanguages });
        set({ getLanguagesStatus: 'SUCCESS' });
        return result;
      } catch (e) {
        set({ getLanguagesStatus: 'FAIL' });
        return Promise.reject(e);
      }
    },
    getPaymentOptions: async () => {
      try {
        set({ getPaymentOptionsStatus: 'LOADING' });
        const result = await getPaymentOptions();
        const updatedPaymentOptions = result.result.map((item) => ({ label: item.name, value: item.id }));
        set({ paymentOptions: updatedPaymentOptions });
        set({ getPaymentOptionsStatus: 'SUCCESS' });
        return result;
      } catch (e) {
        set({ getPaymentOptionsStatus: 'FAIL' });
        return Promise.reject(e);
      }
    },
    getSpecialties: async () => {
      try {
        set({ getSpecialtiesStatus: 'LOADING' });
        const result = await getSpecialties();
        const updatedPaymentOptions = result.result.map((item) => ({ label: item.name, value: item.id }));
        set({ specialties: updatedPaymentOptions });
        set({ getSpecialtiesStatus: 'SUCCESS' });
        return result;
      } catch (e) {
        set({ getSpecialtiesStatus: 'FAIL' });
        return Promise.reject(e);
      }
    },
    getLicenses: async () => {
      try {
        set({ getLicensesStatus: 'LOADING' });
        const result = await getLicenses();
        const updatedLicenses = result.result.map((item) => ({ label: item.name, value: item.id }));
        set({ licenses: updatedLicenses });
        set({ getLicensesStatus: 'SUCCESS' });
        return result;
      } catch (e) {
        set({ getLicensesStatus: 'FAIL' });
        return Promise.reject(e);
      }
    },
    reset: () => {
      set(initialState);
    },
  })),
);
