import React from 'react';
import Tab from '@mui/material/Tab';
import { TTabsProps, TTab } from './Tabs.types';
import { STabs } from './Tabs.styles';

const Tabs = ({ tabs, activeTab, ...props }: TTabsProps) => {
  return (
    <STabs
      value={typeof activeTab === 'string' || typeof activeTab === 'number' ? activeTab : activeTab?.value || false}
      {...props}
    >
      {tabs.map((tab) => (
        <Tab key={tab.value} disableRipple {...tab} />
      ))}
    </STabs>
  );
};

export default Tabs;
export type { TTab };
