import styled from 'styled-components';
import { Colors } from 'styles/variables';
import { TStyledRainbowLacesProps } from 'components/Base/RainbowLaces/ReinbowLaces.types';

export const SRainbowLaces = styled.div<TStyledRainbowLacesProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  div {
    &:nth-child(1) {
      border-bottom: ${({ $height }) => `${$height || 4}px solid ${Colors.Red}`};
    }

    &:nth-child(2) {
      border-bottom: ${({ $height }) => `${$height || 4}px solid ${Colors.Orange}`};
    }

    &:nth-child(3) {
      border-bottom: ${({ $height }) => `${$height || 4}px solid ${Colors.Yellow}`};
    }

    &:nth-child(4) {
      border-bottom: ${({ $height }) => `${$height || 4}px solid ${Colors.Green}`};
    }

    &:nth-child(5) {
      border-bottom: ${({ $height }) => `${$height || 4}px solid ${Colors.Blue}`};
    }

    &:nth-child(6) {
      border-bottom: ${({ $height }) => `${$height || 4}px solid ${Colors.Purple}`};
    }
  }
`;
