import React from 'react';
import { TRainbowLacesProps } from 'components/Base/RainbowLaces/ReinbowLaces.types';
import { SRainbowLaces } from './RainbowLaces.styles';

const RainbowLaces = ({ height }: TRainbowLacesProps) => {
  return (
    <SRainbowLaces $height={height} className='rainbow-laces'>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </SRainbowLaces>
  );
};

export default RainbowLaces;
