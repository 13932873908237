import React from 'react';
import { Outlet } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as AuthLogo } from 'assets/icons/authLogo.svg';
import { Breakpoint } from 'styles/variables';
import { SLayout } from './Layout.styles';

const Layout = () => {
  const isMobile = useMediaQuery(`(max-width:${Breakpoint.Small})`);

  return (
    <SLayout>
      <div className='left-section'>{isMobile ? <Logo width={239} height={62} /> : <AuthLogo />}</div>
      <div className='right-section'>
        <Outlet />
      </div>
    </SLayout>
  );
};

export default Layout;
