import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/sortArrow.svg';
import { ReactComponent as HeaderLogo } from 'assets/icons/headerLogo.svg';
import Tabs from 'components/Base/Tabs';
import { Breakpoint, Colors } from 'styles/variables';
import RainbowLaces from 'components/Base/RainbowLaces';
import DropdownActions from 'components/Base/DropdownActions';
import InstallDesktopApp from 'components/Shared/InstallDesktopApp';
import { RoutePaths, USER_PRIVATE_BASE_URL, USER_PUBLIC_BASE_URL } from 'routes/Routes.types';
import SideMenu from 'components/Shared/SideMenu';
import AuthService from 'services/AuthService';
import { THeaderProps } from './Header.types';
import { SHeader } from './Header.styles';

const Header = ({ tabs, activeTab, onTabChange }: THeaderProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(`(max-width:${Breakpoint.Small})`);
  const isTablet = useMediaQuery(`(max-width:${Breakpoint.Medium})`);
  const slag = location.pathname.split('/')[2];

  const onLogout = () => {
    AuthService.logout(navigate, { redirectPath: `${USER_PUBLIC_BASE_URL}${RoutePaths.LOGIN}` });
  };

  const onProfileClick = () => {
    navigate(`${USER_PRIVATE_BASE_URL}/${RoutePaths.PROFILE}`);
  };

  return (
    <SHeader>
      <div className='header-content'>
        <div className='header-left-section'>
          <div>
            <HeaderLogo />
            {isMobile && <InstallDesktopApp />}
          </div>
          <Tabs tabs={tabs} activeTab={activeTab} onChange={onTabChange} color={Colors.White} />
        </div>
        <div className='header-right-section'>
          {isTablet ? (
            <SideMenu />
          ) : (
            <DropdownActions
              actionButton={
                <div className='header-dropdown-actions'>
                  <UserIcon fill={slag === RoutePaths.PROFILE ? Colors.Secondary : Colors.White} />
                  <ArrowIcon fill={slag === RoutePaths.PROFILE ? Colors.Secondary : Colors.White} fillOpacity={1} />
                </div>
              }
            >
              <li onClick={() => onProfileClick()}>My profile</li>
              <li onClick={() => onLogout()}>Logout</li>
            </DropdownActions>
          )}
        </div>
      </div>
      <RainbowLaces />
    </SHeader>
  );
};

export default Header;
