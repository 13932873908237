export enum RoutePaths {
  WELCOME = 'welcome',
  LOGIN = 'login',
  SIGNUP = 'sign-up',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password/:code',
  TERMS = 'terms-and-condition',
  ADMIN_MANAGEMENT = 'admin-management',
  ADMIN_DETAILS = 'admin-management/details',
  PROVIDERS = 'providers',
  CREATE_PROVIDER = 'providers/create',
  WELLNESS = 'wellness',
  WELLNESS_CATEGORY = 'wellness/category',
  PROVIDER_DIRECTORY = 'provider-directory',
  PROFILE = 'profile',
  CRISIS_SUPPORT = 'crisis-support',
  HOME = 'home',
}

export const ADMIN_PUBLIC_BASE_URL = '/admin';
export const ADMIN_PRIVATE_BASE_URL = '/admin/app';
export const USER_PUBLIC_BASE_URL = '/';
export const USER_PRIVATE_BASE_URL = '/app';
