import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { RoutePaths, USER_PUBLIC_BASE_URL, USER_PRIVATE_BASE_URL } from 'routes/Routes.types';
import { UserPublicRoutes } from './PublicRoutes';
import { UserPrivateRoutes } from './PrivateRoutes';
import AuthService from '../services/AuthService';

const Routing = () => {
  const navigate = useNavigate();

  const handleUnauthorized = () => {
    AuthService.logout(navigate, { redirectPath: `${USER_PUBLIC_BASE_URL}${RoutePaths.LOGIN}` });
  };
  useEffect(() => {
    window.addEventListener('user_logout', handleUnauthorized);

    return () => {
      window.removeEventListener('user_logout', handleUnauthorized);
    };
  }, []);

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path={`${USER_PUBLIC_BASE_URL}*`} element={<UserPublicRoutes />} />
        <Route path={`${USER_PRIVATE_BASE_URL}/*`} element={<UserPrivateRoutes />} />
        {/* <Route path={`${ADMIN_PUBLIC_BASE_URL}/*`} element={<AdminPublicRoutes />} /> */}
        {/* <Route path={`${ADMIN_PRIVATE_BASE_URL}/*`} element={<AdminPrivateRoutes />} /> */}
      </Routes>
    </Suspense>
  );
};

export default Routing;
