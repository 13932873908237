import styled from 'styled-components';
import { Breakpoint } from 'styles/variables';

export const SLayout = styled.div`
  position: relative;
  min-height: 100vh;

  .page-content {
    padding: 24px 48px;
    min-height: calc(100vh - 96px);
  }

  @media screen and (max-width: ${Breakpoint.Small}) {
    .page-content {
      padding: 12px 14px;
    }
  }
`;
