import React from 'react';
// import { ReactComponent as KitIcon } from 'assets/icons/kit.svg';
// import { ReactComponent as BellIcon } from 'assets/icons/bell.svg';
// import { ReactComponent as RewardIcon } from 'assets/icons/reward.svg';
// import { ReactComponent as MessageIcon } from 'assets/icons/message.svg';
// import { ReactComponent as StarIcon } from 'assets/icons/starRounded.svg';
import { ReactComponent as WellnessIcon } from 'assets/icons/wellness.svg';
// import { ReactComponent as AnalyticsIcon } from 'assets/icons/analytics.svg';
import { ReactComponent as ProvidersIcon } from 'assets/icons/providers.svg';
// import { ReactComponent as PersonIcon } from 'assets/icons/personRounded.svg';
import { ReactComponent as ManagementIcon } from 'assets/icons/management.svg';
import { RoutePaths } from 'routes/Routes.types';
import { TMenuItem } from './SideMenu.types';

export const menuItems: TMenuItem[] = [
  {
    id: 1,
    label: 'Admin Management',
    icon: <ManagementIcon width={26} height={26} />,
    path: `${RoutePaths.ADMIN_MANAGEMENT}`,
  },
  // { id: 2, label: 'User Management', icon: <PersonIcon width={26} height={26} /> },
  {
    id: 3,
    label: 'Providers',
    icon: <ProvidersIcon width={26} height={26} />,
    path: `${RoutePaths.PROVIDERS}`,
  },
  // { id: 4, label: 'Message Library', icon: <MessageIcon width={26} height={26} /> },
  {
    id: 5,
    label: 'Wellness Content Library',
    icon: <WellnessIcon width={26} height={26} />,
    path: `${RoutePaths.WELLNESS}`,
  },
  // { id: 6, label: 'Partners & Rewards', icon: <RewardIcon width={26} height={26} /> },
  // { id: 7, label: 'Business Offer Codes', icon: <StarIcon width={26} height={26} /> },
  // { id: 8, label: 'Manage App Notifications', icon: <KitIcon width={26} height={26} /> },
  // { id: 9, label: 'Notifications', icon: <BellIcon width={26} height={26} /> },
  // { id: 10, label: 'Analytics', icon: <AnalyticsIcon width={26} height={26} /> },
];
