import { createTheme } from '@mui/material/styles';
import { Colors, FontFamily, FontSize, FontWeight } from './variables';

export const theme = createTheme({
  palette: {
    primary: { main: Colors.Primary },
    secondary: { main: Colors.Secondary },
    error: { main: Colors.Error },
    default: { main: Colors.Gray, dark: Colors.GrayDark },
    black: { main: Colors.TextPrimary, dark: Colors.Black },
    white: { main: Colors.White, dark: Colors.White },
    common: {
      black: Colors.Black,
      white: Colors.White,
    },
  },
  typography: {
    fontFamily: FontFamily.Primary,
    h1: {
      fontSize: FontSize.FontXXL,
      fontWeight: FontWeight.Bold,
      color: Colors.TextSecondary,
      letterSpacing: '1.6px',
    },
    h2: {
      fontSize: FontSize.FontXL,
      fontWeight: FontWeight.Bold,
      color: Colors.TextSecondary,
      lineHeight: '28px',
      letterSpacing: '1.2px',
    },
    h3: {
      fontSize: FontSize.FontLG,
      fontWeight: FontWeight.Bold,
      color: Colors.TextSecondary,
      letterSpacing: '0.9px',
      lineHeight: '24px',
    },
    h4: {
      fontSize: FontSize.FontMD,
      fontWeight: FontWeight.Bold,
      color: Colors.TextSecondary,
      lineHeight: '20px',
      letterSpacing: '0.8px',
    },
    h5: {
      fontSize: FontSize.FontSM,
      fontWeight: FontWeight.Bold,
      color: Colors.TextSecondary,
      lineHeight: '16px',
    },
    h6: {
      fontSize: FontSize.FontXS,
      fontWeight: FontWeight.Bold,
      color: Colors.TextSecondary,
    },
    subtitle1: {
      fontSize: FontSize.FontSM,
      color: Colors.TextSecondary,
    },
    subtitle2: {
      fontSize: FontSize.FontXS,
      color: Colors.TextSecondary,
    },
    body1: {
      fontSize: FontSize.FontMD,
      color: Colors.TextSecondary,
      letterSpacing: '0.8px',
    },
    body2: {
      fontSize: FontSize.FontSM,
      color: Colors.TextSecondary,
      lineHeight: '20px',
    },
    button: {
      fontSize: FontSize.FontMD,
      color: Colors.TextSecondary,
    },
  },
});

export default theme;
