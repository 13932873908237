import React, { KeyboardEvent, MouseEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as HamburgerIcon } from 'assets/icons/hamburger.svg';
import { ReactComponent as CloseOutlined } from 'assets/icons/closeOutlined.svg';
import Typography from 'components/Base/Typography';
import { FontFamily } from 'styles/variables';
import { RoutePaths, USER_PRIVATE_BASE_URL } from 'routes/Routes.types';
import { tabs } from 'components/Shared/Layout/Layout.data';
import RainbowLaces from 'components/Base/RainbowLaces';
import AuthService from 'services/AuthService';
import { SDrawer } from './SideMenu.styles';

const SideMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const [activeTab, setActiveTab] = useState(location.pathname.split('/')[2] || tabs[0].value);

  const toggleMenu = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpenMenu(open);
  };

  const onTabChange = (e: React.SyntheticEvent, tab: string) => {
    const matchingTab = tabs.find((t) => t.value === tab);

    if (matchingTab?.external) {
      window.open(matchingTab.value, '_blank');
      return;
    }

    setActiveTab(matchingTab?.value);
    navigate(`${USER_PRIVATE_BASE_URL}/${tab}`);
  };

  const onViewProfile = () => {
    navigate(`${USER_PRIVATE_BASE_URL}/${RoutePaths.PROFILE}`);
  };

  const onLogout = () => {
    AuthService.logout(navigate, { redirectPath: `/${RoutePaths.LOGIN}` });
  };

  return (
    <div>
      {openMenu ? (
        <CloseIcon className='pointer align-middle' onClick={toggleMenu(false)} width={32} height={32} />
      ) : (
        <HamburgerIcon className='pointer align-middle' onClick={toggleMenu(true)} width={32} height={16} />
      )}
      <SDrawer anchor='right' open={openMenu} onClose={toggleMenu(false)}>
        <div>
          <div className='d-flex justify-content-between align-items-end cg-2 mb-10'>
            <Typography variant='h1' fontFamily={FontFamily.Secondary}>
              Menu
            </Typography>
            <div>
              <CloseOutlined className='pointer' onClick={toggleMenu(false)} />
            </div>
          </div>
          <div className='menu-items-container'>
            <ul className='menu-items'>
              <li className='menu-item' onClick={() => onViewProfile()}>
                <Typography variant='h4' className='menu-item-label'>
                  My Profile
                </Typography>
              </li>
              {tabs.map((item) => (
                <li
                  key={item.id}
                  className={clsx('menu-item', { active: item.value === activeTab })}
                  onClick={(e) => onTabChange(e, item.value)}
                >
                  <Typography variant='h4' className='menu-item-label'>
                    {item.label}
                  </Typography>
                </li>
              ))}
              <li className='menu-item' onClick={() => onLogout()}>
                <Typography variant='h4' className='menu-item-label'>
                  Logout
                </Typography>
              </li>
            </ul>
          </div>
        </div>
        <RainbowLaces />
      </SDrawer>
    </div>
  );
};

export default SideMenu;
