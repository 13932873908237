import React, { useEffect, useState } from 'react';
import Button from 'components/Base/Button';
import { BeforeInstallPromptEvent } from './InstallDesktopApp.types';

const InstallDesktopApp = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState<BeforeInstallPromptEvent | null>(null);

  const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  useEffect(() => {
    const handler = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };

    window.addEventListener('beforeinstallprompt', handler);
  }, []);

  if (!supportsPWA) {
    return null;
  }

  return (
    <Button variant='text' color='white' onClick={onClick}>
      App
    </Button>
  );
};

export default InstallDesktopApp;
