import styled from 'styled-components';
import { Breakpoint, Colors } from 'styles/variables';

export const SHeader = styled.div`
  background: ${Colors.Primary};

  .header-content {
    padding: 0 100px 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    column-gap: 54px;

    .header-left-section {
      display: flex;
      align-items: flex-end;
      column-gap: 54px;
    }

    .header-right-section {
      display: flex;
      column-gap: 24px;
      align-items: center;
    }

    .header-dropdown-actions {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    .MuiTabs-root {
      margin-bottom: -10px;
    }

    svg {
      vertical-align: middle;
    }
  }

  @media screen and (max-width: ${Breakpoint.Large}) {
    .header-content {
      padding: 0 24px 10px;
    }
  }

  @media screen and (max-width: ${Breakpoint.Medium}) {
    .header-content {
      padding: 0 24px 10px;

      .MuiTabs-root {
        display: none;
      }
    }
  }
`;
