import { createGlobalStyle } from 'styled-components';
import { Breakpoint, Colors } from 'styles/variables';

export const generateSpaceClassNames = (array: number[]) => {
  let classNames = ``;
  array.forEach((n, i) => {
    classNames += `
      .mx-${i} {
        margin: 0 ${n}px !important;
      }
      .my-${i} {
          margin: ${n}px 0 !important;
      }
      .m-${i} {
          margin: ${n}px !important;
      }
      .mt-${i} {
          margin-top: ${n}px !important;
      }
      .mb-${i} {
          margin-bottom: ${n}px !important;
      }
      .ml-${i} {
          margin-left: ${n}px !important;
      }
      .mr-${i} {
          margin-right: ${n}px !important;
      }
      .pt-${i} {
          padding-top: ${n}px !important;
      }
      .pb-${i} {
          padding-bottom: ${n}px !important;
      }
      .pl-${i} {
          padding-left: ${n}px !important;
      }
      .pr-${i} {
          padding-right: ${n}px !important;
      }
      .px-${i} {
          padding: 0 ${n}px !important;
      }
      .py-${i} {
          padding: ${n}px 0 !important;
      }
      .p-${i} {
          padding: ${n}px !important;
      }
      .g-${i} {
          gap: ${n}px !important;
      }
      .cg-${i} {
          column-gap: ${n}px !important;
      }
      .rg-${i} {
          row-gap: ${n}px !important;
      }
   `;
  });

  return classNames;
};

const spaces = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40];

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
    background: #FFFFFF;
    font-family: Poppins, sans-serif;
    box-sizing: border-box;
  }

  input {
    &[type="number"]::-webkit-outer-spin-button,
    &[type="number"]::-webkit-inner-spin-button {
      display: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
  
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }

  ::-webkit-scrollbar-track {
    background: ${Colors.GrayLight};
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: ${Colors.Primary}CC;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Colors.Primary}AA;
  }

  ${() => generateSpaceClassNames(spaces)}
  .w-100 {
    width: 100%;
  }

  .w-75 {
    width: 75%;
  }

  .w-50 {
    width: 50%;
  }

  .w-25 {
    width: 25%;
  }

  .h-100 {
    height: 100%;
  }
  
  .d-inline-block {
      display: inline-block;
  }

  .d-flex {
    display: flex;
  }

  .d-flex-column {
    display: flex;
    flex-direction: column;
  }

  .justify-content-center {
    justify-content: center !important;
  }
  .justify-content-start {
    justify-content: flex-start;
  }
  .justify-content-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }
  .align-items-start {
    align-items: flex-start;
  }
  .align-items-end {
    align-items: flex-end;
  }
  
  .flex-1 {
    flex: 1;
  }
  .flex-grow-1 {
    flex-grow: 1;
  }
  .flex-shrink-1 {
    flex-shrink: 1;
  }
  
  .fit-content {
    width: fit-content;
  }

  .underline {
    text-decoration: underline !important;
  }

  .pointer {
    cursor: pointer;
  }

  .align-middle {
    vertical-align: middle !important;
  }
  
  .text-center {
    text-align: center;
  }
  
  .position-relative {
      position: relative;
  }
  
  .inline-block {
    display: inline-block !important;
  }
  
  
  .break-all {
    word-break: break-all;
  }
  
  .ellipsis {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }

  @media screen and (max-width: ${Breakpoint.Medium}) {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default GlobalStyle;
