import Cookies from 'js-cookie';

interface LogoutOptions {
  redirectPath: string;
}

class AuthService {
  public static logout(navigate: (path: string) => void, options: LogoutOptions = { redirectPath: '/login' }): void {
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });

    localStorage.clear();

    navigate(options.redirectPath);
  }
}

export default AuthService;
