import React, { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import 'styles/font.css';
import theme from 'styles/theme';
import GlobalStyle from 'styles/globalStyles';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import { useGlobalStore } from 'store/global';
import Routing from './routes';
import { StyledContainer } from './App.styles';

const App = () => {
  const getLicenses = useGlobalStore((state) => state.getLicenses);
  const getAgeGroups = useGlobalStore((state) => state.getAgeGroups);
  const getLanguages = useGlobalStore((state) => state.getLanguages);
  const getUserRaces = useGlobalStore((state) => state.getUserRaces);
  const getPronounces = useGlobalStore((state) => state.getPronounces);
  const getSpecialties = useGlobalStore((state) => state.getSpecialties);
  const getPaymentOptions = useGlobalStore((state) => state.getPaymentOptions);
  const getLGBTQIdentities = useGlobalStore((state) => state.getLGBTQIdentities);
  const getGenderIdentities = useGlobalStore((state) => state.getGenderIdentities);
  const getSexualOrientations = useGlobalStore((state) => state.getSexualOrientations);

  useEffect(() => {
    getLicenses();
    getUserRaces();
    getAgeGroups();
    getLanguages();
    getPronounces();
    getSpecialties();
    getPaymentOptions();
    getLGBTQIdentities();
    getGenderIdentities();
    getSexualOrientations();
  }, []);

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <StyledContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            closeButton={false}
            pauseOnHover
            theme='light'
          />
          <GlobalStyle />
          <Routing />
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
};

export default App;
