import { TTab } from 'components/Base/Tabs';
import { RoutePaths } from 'routes/Routes.types';

export const tabs: TTab[] = [
  { id: '5', label: 'Home', value: RoutePaths.HOME },
  { id: '1', label: 'Provider Directory', value: RoutePaths.PROVIDER_DIRECTORY },
  { id: '2', label: 'Wellness Resources', value: RoutePaths.WELLNESS },
  { id: '3', label: 'Crisis Resources', value: RoutePaths.CRISIS_SUPPORT },
  { id: '4', label: 'Peer Support', value: 'https://wisdo.com/co/youflourish', external: 1 },
];
