import styled from 'styled-components';
import { Colors } from 'styles/variables';

export const SHeader = styled.div`
  background: ${Colors.Primary};

  .header-content {
    padding: 28px 54px;
    display: flex;
    align-items: center;
    column-gap: 56px;
  }
`;
