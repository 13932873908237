import React, { Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import UserLayout from 'components/Shared/Layout';
import AdminLayout from 'components/Shared/Admin/Layout';
import { adminPrivateRoutes, userPrivateRoutes } from './Routes.data';
import { ADMIN_PRIVATE_BASE_URL, ADMIN_PUBLIC_BASE_URL, RoutePaths, USER_PRIVATE_BASE_URL } from './Routes.types';
import Terms from '../pages/Terms';

export const AdminPrivateRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const admin = localStorage.getItem('admin');

    if (!admin) {
      navigate(`${ADMIN_PUBLIC_BASE_URL}/${RoutePaths.LOGIN}`);
    }
  }, []);

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<AdminLayout />}>
          <Route
            path='/'
            element={<Navigate replace to={`${ADMIN_PRIVATE_BASE_URL}/${RoutePaths.ADMIN_MANAGEMENT}`} />}
          />
          {adminPrivateRoutes.map(({ path, Component }) => (
            <Route key={path} path={`${path}/*`} element={<Component />} />
          ))}
          <Route
            path='*'
            element={<Navigate replace to={`${ADMIN_PRIVATE_BASE_URL}/${RoutePaths.ADMIN_MANAGEMENT}`} />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export const UserPrivateRoutes = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem('user');

    if (!user) {
      navigate(`/${RoutePaths.LOGIN}`);
    }
  }, []);

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route element={<UserLayout />}>
          <Route path='/' element={<Navigate replace to={`${USER_PRIVATE_BASE_URL}/${RoutePaths.HOME}`} />} />
          {userPrivateRoutes.map(({ path, Component }) => (
            <Route key={path} path={`${path}/*`} element={<Component />} />
          ))}
          <Route key={RoutePaths.TERMS} path={RoutePaths.TERMS} element={<Terms />} />
          <Route path='*' element={<Navigate replace to={`${USER_PRIVATE_BASE_URL}/${RoutePaths.HOME}`} />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
