import React, { MouseEvent, useState } from 'react';
import { ReactComponent as ThreeDots } from 'assets/icons/threeDots.svg';
import { TDropdownActionsProps } from './DropdownActions.types';
import { SDropdownActions, SPopover } from './DropdownActions.styles';

const DropdownActions = ({
  children,
  actionButton,
  handleClose = () => null,
  size = 16,
  popoverProps,
  color,
}: TDropdownActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const onDotsClick = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onClose = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
    handleClose();
  };

  const closePopover = () => {
    setTimeout(() => {
      setAnchorEl(null);
    }, 300);
  };

  return (
    <SDropdownActions>
      {actionButton ? (
        <div role='presentation' onClick={onDotsClick}>
          {actionButton}
        </div>
      ) : (
        <ThreeDots width={size} height={size} fill={color} onClick={onDotsClick} />
      )}
      <SPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        {...popoverProps}
      >
        <ul className='actions-wrapper' onClick={closePopover}>
          {children}
        </ul>
      </SPopover>
    </SDropdownActions>
  );
};

export default DropdownActions;
